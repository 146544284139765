
import { Component, Vue } from 'vue-property-decorator'
import echarts from 'echarts'
@Component
export default class History extends Vue {
  private searchInfo: { dateRange: Array<string> } = {
    dateRange: []
  }

  private dateRange: {min: string | null; max: string | null } = {
    min: null,
    max: null
  }

  private pickerOptions = {
    // 选中日期后会执行的回调
    // 日期范围不超过30天 ，选中当天之前的时期
    onPick: (date: {maxDate: any; minDate: any}) => {
      this.dateRange.max = date.maxDate
      this.dateRange.min = date.minDate
    },
    disabledDate: (time: any) => {
      if (this.dateRange.min) {
        return (time.getTime() > new Date(this.dateRange.min).getTime() + 60 * 60 * 24 * 30 * 1000) ||
        (time.getTime() >= Date.now()) ||
        (time.getTime() < new Date(this.dateRange.min).getTime() - 60 * 60 * 24 * 30 * 1000)
      } else {
        return time.getTime() >= Date.now()
      }
    }
  }

  private echart: any
  private echartSelect = 'water'
  private echartData: {water: []; alarm: []; collectTime: []; deviceNumber: string } = {
    water: [],
    alarm: [],
    collectTime: [],
    deviceNumber: ''
  }

  private colorList = {
    current: ['rgba(44, 142, 255, 1)', 'rgba(44, 142, 255, 0.2)'],
    alarm: ['rgba(254, 76, 39, 1) ', 'rgba(254, 76, 39, 0.2)']
  }

  private tableData: { loading: boolean; data: Array<{collectTime: string; waterLevel: string }> } = {
    loading: false,
    data: []
  }

  page = 1
  size = 10
  total = 0

  $refs!: {
    echart: any;
  }

  get deviceId () {
    return this.$route.params.deviceId as string || ''
  }

  created () {
    this.initTime()
    this.getDataChart()
    this.getData()
  }

  mounted () {
    window.addEventListener('resize', this.resize)
  }

  initTime () {
    const date = new Date()
    const val = date.getFullYear() + '-' + this.addZero(date.getMonth() + 1) + '-' + this.addZero(date.getDate())
    this.searchInfo.dateRange = [val, val]
  }

  addZero (val: number) {
    return val < 10 ? '0' + val : String(val)
  }

  getDataChart (): void {
    if (this.searchInfo.dateRange && this.searchInfo.dateRange[0]) {
      this.$axios.get(this.$apis.pondMonitor.selectDraftDataList, {
        deviceId: this.deviceId,
        startTime: this.searchInfo.dateRange[0],
        endTime: this.searchInfo.dateRange[1]
      }).then((res) => {
        this.echartData.water = res.reportData.dataList || []
        this.echartData.collectTime = res.reportData.dataTimeList || []
        this.echartData.alarm = res.reportData.alarmList || []
        this.echartData.deviceNumber = res.reportData.deviceNumber || ''
        this.draw()
      })
    } else {
      this.$message.error('请选择查询日期范围')
    }
  }

  getData (): void {
    if (this.searchInfo.dateRange && this.searchInfo.dateRange[0]) {
      this.tableData.loading = true
      this.$axios.get(this.$apis.devices.getHistoryByTypeId('1024'), {
        deviceId: this.deviceId,
        startTime: this.searchInfo.dateRange[0],
        endTime: this.searchInfo.dateRange[1],
        page: this.page,
        size: this.size
      }).then((res) => {
        this.total = res.total || 0
        this.tableData.data = res.list || []
      }).finally(() => {
        this.tableData.loading = false
      })
    } else {
      this.$message.error('请选择查询日期范围')
    }
  }

  onSearch () {
    this.page = 1
    this.getDataChart()
    this.getData()
  }

  // 折线图
  draw () {
    this.$nextTick(() => {
      this.echart = echarts.init(this.$refs.echart)
      const option: any = {
        tooltip: {
          trigger: 'axis',
          confine: true,
          textStyle: {
            fontSize: 12
          }
        },
        grid: {
          top: 75,
          left: 15,
          right: 10,
          bottom: 10,
          containLabel: true
        },
        backgroundColor: 'transparent',
        legend: {
          icon: 'rect',
          itemWidth: 12,
          itemHeight: 1,
          orient: 'horizontal',
          align: 'left',
          top: 35,
          left: 0,
          textStyle: {
            fontSize: 12,
            fontWeight: 400,
            color: '#8c8c8c',
            lineHeight: 14
          },
          data: ['当前水位', '警戒水位']
        },
        color: [this.colorList.current[0], this.colorList.alarm[0]],
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: {
              boundaryGap: true
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          axisLabel: {
            show: true,
            margin: 15,
            textStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          data: this.echartData.collectTime
        },
        yAxis: [{
          type: 'value',
          axisLabel: {
            formatter: '{value}cm'
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.15)'
            }
          }
        }],
        series: [{
          name: '当前水位',
          type: 'line',
          symbolSize: 0,
          yAxisIndex: 0,
          smooth: true,
          lineStyle: {
            width: 4,
            shadowBlur: 0,
            shadowColor: this.colorList.current[1],
            shadowOffsetY: 20
          },
          itemStyle: {
            normal: {
              borderWidth: 4,
              borderColor: this.colorList.current[0]
            }
          },
          data: this.echartData.water
        }, {
          name: '警戒水位',
          type: 'line',
          symbolSize: 0,
          yAxisIndex: 0,
          smooth: true,
          lineStyle: {
            type: 'dashed',
            width: 2,
            shadowBlur: 0,
            shadowColor: 'none',
            shadowOffsetY: 20
          },
          itemStyle: {
            normal: {
              borderWidth: 4,
              borderColor: this.colorList.alarm[0]
            }
          },
          data: this.echartData.alarm
        }]
      }
      this.echart.setOption(option, true)
    })
  }

  resize () {
    if (this.echart) {
      this.echart.resize()
    }
  }

  destroyed () {
    window.removeEventListener('resize', this.resize)
  }
}
